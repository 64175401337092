import { useEffect, useMemo } from 'react';

export const calculateIsVisible = (
	index: number,
	currentRange: { maxRange: number; minRange: number },
) => {
	return index >= currentRange.minRange && index <= currentRange.maxRange;
};

const useIsWithinRange = (
	isVisible: boolean,
	index: number,
	currentRange: { maxRange: number; minRange: number },
	setIsVisible: (value: boolean) => void,
) => {
	const isWithinRange = useMemo(
		() => isVisible || calculateIsVisible(index, currentRange),
		[isVisible, index, currentRange],
	);

	useEffect(() => {
		if (isWithinRange) {
			setIsVisible(true);
		}
	}, [isWithinRange, setIsVisible]);
};

export default useIsWithinRange;
