import { awards as awardsSettings } from '@settings';
import { Img, Picture, Source } from '@xo-union/tk-component-picture';
import React, { useMemo } from 'react';
import { Decorated } from 'types/vendor';
import { withinDateRange } from '../../../../utils/withinDateRange';
import { BOW_HOF_IMAGES } from './constants';

const { startDate, endDate } = awardsSettings;

interface AwardSingleItemsProps {
	vendor: Decorated;
}

export const AwardSingleItem = ({ vendor }: AwardSingleItemsProps) => {
	const sortedAwards = vendor.awards.sort().reverse();
	const firstAward = sortedAwards[0];

	const [mediaId, awardName] = useMemo(() => {
		if (firstAward === 'BOW2024' && !withinDateRange({ startDate, endDate })) {
			return [null, null];
		}
		const bowImage = BOW_HOF_IMAGES[firstAward as keyof typeof BOW_HOF_IMAGES];
		return [bowImage?.id, bowImage?.name];
	}, [firstAward]);

	if (!mediaId) {
		return null;
	}

	return (
		<Picture lazy id={mediaId} key={firstAward} env="production">
			<Source fit height={90} width={90} />
			<Img fit alt={awardName} height={90} width={90} />
		</Picture>
	);
};
