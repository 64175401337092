import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { SecondaryCTASourceContent } from '@typings/sourceContent';
import Icon, { IconProps } from '@xo-union/tk-component-icons';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import InlineRfqModal from '../../containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { generateDefaultField } from '../../containers/InlineRFQ/utils';
import Styles from './styles.scss';

export type OwnProps = {
	icon: IconProps['name'];
	headerText: string;
	linkText: string;
	placeholderRfqText: string;
	sourceContent: SecondaryCTASourceContent;
	className?: string;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type SecondaryCTAProps = OwnProps & StateProps;

const SecondaryCTA: FC<SecondaryCTAProps> = (props) => {
	const {
		icon,
		headerText,
		linkText,
		placeholderRfqText,
		sourceContent,
		conversations,
		vendorId,
		className = '',
	} = props;

	const conversationStarted = Boolean(conversations[vendorId || '']);
	const isInDetailedPricing = useDetailedPricing();

	const localFormContext = useInlineRfqForm({
		freeTextLabel: placeholderRfqText,
		headerText: isInDetailedPricing
			? 'Start the convo'
			: 'Start a conversation',
		textAreaInitialValueOverride: generateDefaultField('textarea'),
		initiator: sourceContent,
	});

	return (
		<>
			<InlineRfqModal context={localFormContext} />
			{!conversationStarted && (
				<div className={className}>
					<div className={Styles['secondary-cta-container']}>
						<div className={Styles.circle}>
							<Icon className={Styles.icon} name={icon} size="lg" />
						</div>
						<div className={Styles.textContainer}>
							<Body1 className={Styles.header} bold>
								{headerText}
							</Body1>
							<DisplayButton
								onClick={localFormContext.openModal}
								className={Styles['action-button']}
								type="button"
							>
								{linkText}
							</DisplayButton>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	conversations: state.messagedVendors.conversations,
	vendorId: state.vendor.vendor?.id,
});

export default connect(mapStateToProps)(SecondaryCTA);
