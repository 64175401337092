import React, { FC } from 'react';
import SecondaryCTA from '../../../secondary_cta';

type Props = {
	conversationStarted: string | undefined;
};

const PricingSecondaryCTA: FC<Props> = ({ conversationStarted }) => {
	if (conversationStarted) {
		return null;
	}

	return (
		<SecondaryCTA
			sourceContent="Pricing CTA"
			headerText="Curious to learn more?"
			linkText="Ask about pricing"
			icon="price"
			placeholderRfqText="Ask about pricing"
		/>
	);
};

export default PricingSecondaryCTA;
