import { useStorefrontFeatureBvcPlusPricing } from '@feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useIsVenue } from '@hooks/use-is-venue';
import { Body1 } from '@xo-union/tk-ui-typography';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { isPostPrimary } from '../../../../utils';
import PriceRange from '../../../PriceRange';
import { buildCreationDate } from '../../../ViewConversation/utils';
import Styles from './styles.scss';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface OwnProps {
	className?: string;
	context: ReturnType<typeof useInlineRfqForm>;
}

type StickyCtaBoxTextProps = OwnProps & StateProps;

export const StickyCtaBoxText: FC<StickyCtaBoxTextProps> = (props) => {
	const {
		className,
		conversations,
		isMobile,
		timestamps,
		vendorId,
		vendorName,
		context,
	} = props;
	const isDetailedPricing = useDetailedPricing();
	const isBvcPlusDetailedPricing = useStorefrontFeatureBvcPlusPricing();
	const isVenue = useIsVenue();

	const { areErrorsInForm, shouldShowErrors } = context;

	if (
		isPostPrimary(
			conversations,
			vendorId,
			context.isModalOpen,
			shouldShowErrors,
			areErrorsInForm,
		)
	) {
		return (
			<Body1 className={isMobile ? Styles.mobileEllipse : Styles.stickyBoxBody}>
				{`You messaged ${vendorName} ${buildCreationDate({
					timestamps,
					vendorId,
				})}`}
			</Body1>
		);
	}

	if (isVenue || isDetailedPricing || isBvcPlusDetailedPricing) {
		return null;
	}

	return <PriceRange className={className || Styles.stickyBoxText} />;
};

const mapStateToProps = (state: Redux.State) => ({
	conversations: state.messagedVendors.conversations,
	isMobile: state.viewport.isMobile,
	timestamps: state.messagedVendors.timestamps,
	vendorId: state.vendor.vendorRaw?.id as string,
	vendorName: state.vendor.vendorRaw?.name,
});

export default connect(mapStateToProps)(StickyCtaBoxText);
