import useIsVisible from '@hooks/useIsVisible';
import React, { forwardRef, useState } from 'react';
import mergeRefs from '../../../../../../../utils/mergeRefs';
import Media from '../../../../../components/Media';
import useCalculateMediaHeight from './hooks/use-calculate-media-height';
import useIsWithinRange, {
	calculateIsVisible,
} from './hooks/use-is-within-range';
import Styles from './styles.scss';

const width = 450;

export interface MediaViewProps {
	currentRange: { maxRange: number; minRange: number };
	index: number;
	setOverviewIndex: (index: number) => void;
	mediaItem: Vendor.Media;
	onClick: (index: number) => void;
}

const MediaView = forwardRef<HTMLDivElement, MediaViewProps>((props, ref) => {
	const { currentRange, index, setOverviewIndex, mediaItem, onClick } = props;

	const [isVisible, setIsVisible] = useState(
		calculateIsVisible(index, currentRange),
	);
	const [localRef] = useIsVisible({
		callback: () => setOverviewIndex(index),
		timeoutMs: 200,
	});
	const mediaRef = mergeRefs(ref, localRef);
	const height = useCalculateMediaHeight(mediaItem, width);

	useIsWithinRange(isVisible, index, currentRange, setIsVisible);

	return (
		<div
			ref={mediaRef}
			className={Styles.mediaItemContainer}
			style={{
				aspectRatio:
					mediaItem.mediaType === 'PHOTO' ? `${width} / ${height}` : '16 / 9',
			}}
			onClick={() => onClick(index)}
			onKeyPress={() => onClick(index)}
			role="button"
			tabIndex={0}
		>
			{isVisible ? (
				<Media
					mediaItem={mediaItem}
					width={width}
					height={height}
					smartCrop
					imageClass={Styles.mediaItemImage}
				/>
			) : null}
		</div>
	);
});

export default MediaView;
