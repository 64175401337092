import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { resolveSourceContent } from '@redux/rfq/utils';
import React, { useContext, VFC } from 'react';
import { connect } from 'react-redux';
import { Raw } from 'types/vendor';
import RecommendedContext from '../../../../contexts/RecommendedContext/RecommendedContext';
import VrmModals from '../../../VendorsSearch/containers/SearchResults/components/RFQModal/components/VrmModals/VrmModals';
import InlineRfq from '../InlineRFQ/InlineRfq';
import InlineRfqModal from '../InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import StickyCtaBox from '../InlineRFQ/components/StickyCtaBox';
import { useInlineRfqForm } from '../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet } from '../InlineRFQ/utils';

type StateProps = ReturnType<typeof mapStateToProps>;
export type CtaWrapperProps = StateProps;

export const CtaWrapper: VFC<CtaWrapperProps> = (props) => {
	const { catCode, viewport, vendorRaw } = props;
	const {
		modal: vrmModal,
		vrmOpenedFrom,
		onClose,
		recommendedVendors,
		setModal,
	} = useContext(RecommendedContext);

	const { isMobile } = viewport;
	const isTablet = checkIsTablet(viewport);
	const initiator = resolveSourceContent(isMobile, isTablet, catCode);
	const isTabletOrMobile = isTablet || isMobile;
	const isInDetailedPricing = useDetailedPricing();
	const isLiteStorefront = useLiteStorefront();

	const formContext = useInlineRfqForm({
		initiator,
		headerText: isInDetailedPricing ? 'Start the convo' : undefined,
	});

	return (
		<>
			{isTabletOrMobile && !isLiteStorefront && (
				<>
					<StickyCtaBox context={formContext} />
					<InlineRfqModal context={formContext} />
				</>
			)}
			{!isTabletOrMobile && !isLiteStorefront && (
				<InlineRfq context={formContext} />
			)}
			{/* photo gallery opens up VRM from inside (nested), to avoid display issues */}
			{vrmModal && vrmOpenedFrom !== 'photo gallery' && (
				<VrmModals
					initiator="inline RFQ pricing top"
					modal={vrmModal}
					onClose={onClose}
					recommendedVendors={recommendedVendors}
					rfqType="inline"
					setModal={setModal}
					vendorRaw={vendorRaw}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	catCode: state.category.code,
	vendorRaw: state.vendor.vendorRaw as Raw,
	viewport: state.viewport,
});

export default connect(mapStateToProps)(CtaWrapper);
