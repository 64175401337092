import { awards as awardsSettings } from '@settings';
import { Img, Picture, Source } from '@xo-union/tk-component-picture';
import { Carousel } from '@xo-union/ui-carousel';
import React, { useMemo } from 'react';
import { Decorated } from 'types/vendor';
import { withinDateRange } from '../../../../utils/withinDateRange';
import { BOW_HOF_IMAGES } from './constants';

const { startDate, endDate } = awardsSettings;

interface AwardItemsProps {
	vendor: Decorated;
	carousel?: boolean | null;
}

interface AwardDetail {
	award: string;
	mediaId: string;
	awardName: string;
}

const AwardItem: React.FC<AwardDetail> = ({ award, mediaId, awardName }) => {
	const imgSize = 90;
	return (
		<Picture lazy id={mediaId} key={award} env="production">
			<Source fit height={imgSize} width={imgSize} />
			<Img fit alt={awardName} height={imgSize} width={imgSize} />
		</Picture>
	);
};

export const AwardItems = ({ vendor, carousel }: AwardItemsProps) => {
	const sortedAwards = new Set(vendor.awards.sort().reverse());

	const awardDetails: AwardDetail[] = useMemo(() => {
		return Array.from(sortedAwards)
			.map((award): AwardDetail | null => {
				if (award === 'BOW2024' && !withinDateRange({ startDate, endDate })) {
					return null;
				}
				const bowImage = BOW_HOF_IMAGES[award as keyof typeof BOW_HOF_IMAGES];
				return {
					award,
					mediaId: bowImage?.id || '',
					awardName: bowImage?.name || '',
				};
			})
			.filter((detail): detail is AwardDetail => detail !== null);
	}, [sortedAwards]);

	if (awardDetails.length === 0) {
		return null;
	}

	const awardsItems = awardDetails.map((detail) => (
		<AwardItem key={detail.award} {...detail} />
	));

	return carousel ? (
		<Carousel gutter={24} minSlideWidth={80} maxSlideWidth={80}>
			{awardsItems}
		</Carousel>
	) : (
		<>{awardsItems}</>
	);
};
