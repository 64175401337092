import { useMemo } from 'react';

const useCalculateMediaHeight = (mediaItem: Vendor.Media, width: number) =>
	useMemo(() => {
		if ('height' in mediaItem && 'width' in mediaItem) {
			const ratio = Number(mediaItem.width) / Number(mediaItem.height);

			return width / ratio;
		}

		return 250;
	}, [mediaItem]);

export default useCalculateMediaHeight;
