import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import {
	PHOTO,
	TOUR360,
	VIDEO,
} from '../../../../../../../../../constants/media';
import VendorAnalytics from '../../../../../../../../decorators/vendorAnalytics';

export type ActionType = 'view photo' | 'view video' | 'dismiss' | 'play tour';

export const DetermineActionType = (action: Vendor.MediaType): ActionType => {
	switch (action) {
		case VIDEO:
			return 'view video';
		case TOUR360:
			return 'play tour';
		case PHOTO:
		default:
			return 'view photo';
	}
};

const useTrackOverviewInteraction = (vendor: Vendor.Decorated) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(action: ActionType, index?: number) => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			const vendorAnalytics = new VendorAnalytics(vendor);

			track({
				event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
				properties: {
					action,
					sourcePage: `${paidStatus} storefront`,
					sourceContent: 'media overview',
					mediaIndex: index,
					mediaType:
						index &&
						vendor.mediaSummary.media &&
						vendor.mediaSummary.media[index].mediaType,
					item:
						index &&
						vendor.mediaSummary.media &&
						vendor.mediaSummary.media[index].url,
					...vendorAnalytics.baseEventProps(),
				},
			});
		},
		[track, vendor],
	);
};

export default useTrackOverviewInteraction;
