import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import { getFilterByMediaType } from '@components/unified-lightbox/helpers';
import useImage from '@hooks/useImage';
import { isStorefrontLightboxRevampSelector } from '@redux/experiments/selectors/storefront-lightbox-revamp';
import { useAppSelector } from '@redux/hooks';
import { useSetAtom } from 'jotai';
import React, { FC, useState } from 'react';
import ImageWrapper from '../../../../../components/Media/ImageWrapper';
import { PLACEHOLDER_CONSTANTS } from '../../../../../components/Media/constants';
import Playable, {
	PlayableRenderProps,
} from '../../../../../components/Media/playable';
import { useInlineRfqForm } from '../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import useLightboxModal from '../../hooks/useLightboxModal';
import useOverviewModal from '../../hooks/useOverviewModal';
import LightboxModal from '../lightbox_modal/LightboxModal';
import OverviewModal from '../overview_modal';
import { getMediaQuickTileText } from './helpers';
import Styles from './styles.scss';

export interface MobileGalleryQuickLinkItemProps {
	mediaBucket: Vendor.Media[];
	trackQuicklinksClick: (type: string) => void;
	context?: ReturnType<typeof useInlineRfqForm>;
}

export const MobileGalleryQuickLinkItem: FC<MobileGalleryQuickLinkItemProps> = (
	props,
) => {
	const [imageStatus, onLoad, onError] = useImage(PLACEHOLDER_CONSTANTS.url);
	const [isOverviewModalOpen, toggleOverviewModal] = useOverviewModal();
	const [isLightboxModalOpen, toggleLightboxModal] = useLightboxModal();
	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const isInLightboxRevampExperiment = useAppSelector(
		isStorefrontLightboxRevampSelector,
	);

	const { mediaBucket, trackQuicklinksClick, context } = props;
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const mediaItem = mediaBucket[0];
	const { mediaType } = mediaItem;

	const tileWidth = 125;
	const tileHeight = 100;

	const placeholderContent = (
		<span className={Styles.quickLinkItemPlaceholderText}>
			{' '}
			Preview Unavailable{' '}
		</span>
	);

	return (
		<Playable
			media={mediaItem}
			render={({ playMedia, stopMedia }: PlayableRenderProps) => {
				const autoplayLightbox = () => {
					playMedia();
					toggleLightboxModal();
				};

				const closeLightbox = () => {
					stopMedia();
					toggleLightboxModal();
				};

				const onClick = async () => {
					trackQuicklinksClick(mediaType);
					if (isInLightboxRevampExperiment) {
						await setOpenUnifiedLightbox({
							overview: mediaBucket.length !== 1,
							indexInAllMedia: 0,
							filter: getFilterByMediaType(mediaType),
						});
						setIsUnifiedLightboxModalOpen(true);
					} else {
						if (mediaBucket.length > 1) return toggleOverviewModal();
						if (mediaItem.mediaType === '360TOUR') return autoplayLightbox();
						return toggleLightboxModal();
					}
				};

				return (
					<>
						<div
							role="button"
							onClick={onClick}
							onKeyDown={onClick}
							tabIndex={0}
							className={Styles.quickLinkContainer}
						>
							<ImageWrapper
								imageClass={Styles.quickLinksImage}
								placeholderClass={Styles.quickLinkItemPlaceholder}
								placeholderContent={placeholderContent}
								imageStatus={imageStatus}
								mediaItem={mediaItem}
								onError={onError}
								onLoad={onLoad}
								height={tileHeight}
								width={tileWidth}
								smartCrop
							/>
							<span className={Styles.quickLinkText}>
								{getMediaQuickTileText(mediaBucket)}
							</span>
						</div>
						{!isInLightboxRevampExperiment && isOverviewModalOpen && (
							<OverviewModal
								isModalOpen={isOverviewModalOpen}
								toggleModal={toggleOverviewModal}
								context={context}
								carouselMedia={mediaBucket}
								isQuickLinkClick
								mediaType={mediaType}
							/>
						)}
						{!isInLightboxRevampExperiment && isLightboxModalOpen && (
							<LightboxModal
								isModalOpen={isLightboxModalOpen}
								toggleModal={closeLightbox}
								closeOverviewModal={() => {
									isOverviewModalOpen && toggleOverviewModal();
								}}
								context={context}
								carouselMedia={mediaBucket}
								isQuickLinkClick
								mediaType={mediaType}
							/>
						)}
						{isInLightboxRevampExperiment && isUnifiedLightboxModalOpen && (
							<UnifiedLightboxModal
								entryPoint="Quicklinks"
								isModalOpen={isUnifiedLightboxModalOpen}
								closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
							/>
						)}
					</>
				);
			}}
		/>
	);
};
