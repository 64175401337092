import React, { forwardRef } from 'react';
import Playable, {
	PlayableRenderProps,
} from '../../../../../components/Media/playable';
import MediaView, { MediaViewProps } from './media-view';

const PlayableMediaView = forwardRef<HTMLDivElement, MediaViewProps>(
	(props: MediaViewProps, ref) => {
		const { mediaItem, onClick } = props;
		return (
			<Playable
				media={mediaItem}
				render={({ playMedia }: PlayableRenderProps) => {
					const playMediaAndClick = (index: number) => {
						playMedia();
						onClick(index);
					};

					return <MediaView {...props} ref={ref} onClick={playMediaAndClick} />;
				}}
			/>
		);
	},
);

export default PlayableMediaView;
