import { useOpenLightbox } from '@components/lightbox/hooks/use-open-lightbox';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Button } from '@xo-union/tk-component-buttons';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { PhotosIcon } from './ components/PhotosIcon';
import Styles from './styles.scss';

export interface SeeAllPhotosCTAProps {
	count: number;
	vendor: Vendor.Decorated;
	onClick?: () => void;
}

export const SeeAllPhotosCTA: VFC<SeeAllPhotosCTAProps> = (props) => {
	const { count, vendor, onClick } = props;
	const openLightbox = useOpenLightbox();
	const { track } = useAnalyticsContext();

	const trackClick = () => {
		const vendorAnalytics = new VendorAnalytics(vendor);
		const sourcePage =
			vendor.purchaseStatus === 'PAID'
				? AnalyticsConstants.PAID_STOREFRONT
				: AnalyticsConstants.UNPAID_STOREFRONT;
		track({
			event: AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
			properties: {
				action: AnalyticsConstants.SEE_ALL_IMAGES,
				sourcePage,
				sourceContent: AnalyticsConstants.MOSAIC,
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	const seeAll = () => {
		if (onClick) {
			onClick();
			trackClick();
		} else {
			openLightbox({ index: -1 });
			trackClick();
		}
	};

	return (
		<Button
			className={Styles.galleryCtas}
			color="secondary"
			onClick={seeAll}
			aria-label="see-all-photos"
			size="md"
		>
			<PhotosIcon />
			<span className={Styles.galleryCtasText}>{`See all (${count})`}</span>
		</Button>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	vendor: state.vendor.vendor,
});

export default connect(mapStateToProps)(SeeAllPhotosCTA);
