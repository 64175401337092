import UnifiedLightboxModal from '@components/unified-lightbox/Lightbox';
import { openUnifiedLightboxAtom } from '@components/unified-lightbox/atoms';
import { getFilterByMediaType } from '@components/unified-lightbox/helpers';
import { isStorefrontLightboxRevampSelector } from '@redux/experiments/selectors/storefront-lightbox-revamp';
import { useAppSelector } from '@redux/hooks';
import { useSetAtom } from 'jotai';
import React, { FC, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { TOUR360 } from '../../../../../../../../constants/media';
import Gallery from '../../../../Gallery';
import useLightboxModal from '../../hooks/useLightboxModal';
import useOverviewModal from '../../hooks/useOverviewModal';
import LightboxModal from '../lightbox_modal/LightboxModal';
import { bucketMedia } from '../mobile_gallery_quick_links/helpers';
import OverviewModal from '../overview_modal';

interface LandscapeGalleryViewProps {
	vendor: Vendor.Decorated;
}

export const LandscapeGalleryView: FC<LandscapeGalleryViewProps> = (props) => {
	const { vendor } = props;
	const carouselMedia = vendor.mediaSummary.media;

	const [focusedToursCollection, setFocusedToursCollection] = useState<
		Vendor.Media[] | null
	>(null);
	const [activeImageIndex, setActiveImageIndex] = useState(0);

	const [isOverviewModalOpen, toggleOverviewModal] = useOverviewModal();
	const [isLightboxModalOpen, toggleLightboxModal] = useLightboxModal();

	const isInLightboxRevampExperiment = useAppSelector(
		isStorefrontLightboxRevampSelector,
	);
	const [isUnifiedLightboxModalOpen, setIsUnifiedLightboxModalOpen] =
		useState<boolean>(false);
	const setOpenUnifiedLightbox = useSetAtom(openUnifiedLightboxAtom);

	const handleLandscapeSeeAllClick = useCallback(async () => {
		if (isInLightboxRevampExperiment) {
			await setOpenUnifiedLightbox({
				overview: true,
				indexInAllMedia: 0,
			});
			setIsUnifiedLightboxModalOpen(true);
		} else {
			setFocusedToursCollection(null);
			toggleOverviewModal();
		}
	}, []);

	const handleSeeAllVirtualToursClick = useCallback(async () => {
		if (!isInLightboxRevampExperiment) {
			const buckets = bucketMedia(carouselMedia as Vendor.Media[]);
			const virtualTourBucket = buckets.get(TOUR360);

			setFocusedToursCollection(virtualTourBucket as Vendor.Media[]);
			toggleOverviewModal();
		}
	}, [carouselMedia]);

	const handleLandscapeGalleryClick = useCallback(async (index: number) => {
		if (isInLightboxRevampExperiment) {
			await setOpenUnifiedLightbox({
				overview: false,
				indexInAllMedia: index,
				filter: getFilterByMediaType(carouselMedia?.[index].mediaType),
			});
			setIsUnifiedLightboxModalOpen(true);
		} else {
			setActiveImageIndex(index);
			toggleLightboxModal();
		}
	}, []);

	return (
		<>
			<Gallery
				lazy
				onMediaClick={handleLandscapeGalleryClick}
				onSeeAllClick={handleLandscapeSeeAllClick}
				onVirtualTourClick={handleSeeAllVirtualToursClick}
			/>
			{isOverviewModalOpen && (
				<OverviewModal
					carouselMedia={focusedToursCollection || carouselMedia}
					isQuickLinkClick={!!focusedToursCollection}
					mediaType={focusedToursCollection ? TOUR360 : undefined}
					isModalOpen={isOverviewModalOpen}
					toggleModal={toggleOverviewModal}
				/>
			)}
			{isLightboxModalOpen && (
				<LightboxModal
					carouselMedia={carouselMedia as Vendor.Media[]}
					overviewIndex={activeImageIndex}
					setActiveImageIndex={setActiveImageIndex}
					isModalOpen={isLightboxModalOpen}
					toggleModal={toggleLightboxModal}
				/>
			)}
			{isInLightboxRevampExperiment && isUnifiedLightboxModalOpen && (
				<UnifiedLightboxModal
					entryPoint="Reviews"
					isModalOpen={isUnifiedLightboxModalOpen}
					closeCallback={() => setIsUnifiedLightboxModalOpen(false)}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State): LandscapeGalleryViewProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<LandscapeGalleryViewProps, {}, {}>(mapStateToProps)(
	LandscapeGalleryView,
);
