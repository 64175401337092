import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import React, { useMemo, FC } from 'react';
import AnalyticsConstants from '../../../../../../../constants/analytics';
import Styles from './MediaSummary.scss';
import { MediaCTA } from './components/MediaCTA/MediaCTA';
import {
	ButtonRole,
	RoundUpOrientation,
} from './components/MediaCTA/constants';

export interface MediaSummaryProps {
	mediaItems: Vendor.IndexedMedia[];
	isVirtualTourOnGrid?: boolean;
	isVideoOnGrid?: boolean;
	onSeeAllClick?: () => void;
	onVirtualTourClick?: () => void;
	openUnifiedLightboxVideos?: (index: number) => void;
	openUnifiedLightboxTours?: (index: number) => void;
}
export const MediaSummary: FC<MediaSummaryProps> = (
	props: MediaSummaryProps,
) => {
	const {
		mediaItems,
		onSeeAllClick,
		onVirtualTourClick,
		isVirtualTourOnGrid,
		isVideoOnGrid,
		openUnifiedLightboxVideos,
		openUnifiedLightboxTours,
	} = props;
	const isMobile = useAppSelector((state) => selectIsMobile(state));

	const videosItems = useMemo(
		() => mediaItems.filter((mediaItem) => mediaItem.mediaType === 'VIDEO'),
		[mediaItems],
	);

	const tourItems = useMemo(
		() => mediaItems.filter((mediaItem) => mediaItem.mediaType === '360TOUR'),
		[mediaItems],
	);

	return (
		<div className={Styles.container}>
			{videosItems.length > 0 && !isVideoOnGrid && (
				<MediaCTA
					mediaItems={videosItems}
					onClick={openUnifiedLightboxVideos || onSeeAllClick}
					singleButton={tourItems.length === 0 || isVirtualTourOnGrid}
					role={ButtonRole.Video}
					round={RoundUpOrientation.Left}
					event={AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION}
					sourceContent={AnalyticsConstants.MOSAIC}
					action={AnalyticsConstants.SEE_VIDEOS}
				/>
			)}
			{tourItems.length > 0 && !isVirtualTourOnGrid && (
				<MediaCTA
					mediaItems={tourItems}
					onClick={openUnifiedLightboxTours || onVirtualTourClick}
					singleButton={videosItems.length === 0 || isVideoOnGrid}
					role={ButtonRole.Tour}
					round={RoundUpOrientation.Right}
					event={AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION}
					sourceContent={AnalyticsConstants.MOSAIC}
					action={AnalyticsConstants.SEE_VIRTUAL_TOUR}
				/>
			)}
		</div>
	);
};
