import { CarouselWithStaticVisibleSlots } from '@xo-union/ui-carousel';
import React, {
	FC,
	MouseEvent,
	ReactNode,
	useCallback,
	useEffect,
} from 'react';
import useCarouselSize from '../../hooks/useCarouselSize';

interface ArrowProps {
	onClick?: (e: MouseEvent) => void;
}

export interface CarouselProps {
	activeImageIndex: number;
	children: ReactNode;
	mediaLength: number;
	renderNextButton: ({ onClick }: ArrowProps) => JSX.Element | null;
	renderPreviousButton: ({ onClick }: ArrowProps) => JSX.Element | null;
	onNavigationChange: (index: number) => void;
}

const Carousel: FC<CarouselProps> = (props) => {
	const {
		activeImageIndex,
		mediaLength,
		children,
		renderNextButton,
		renderPreviousButton,
		onNavigationChange,
	} = props;

	const keydown = useCallback(
		(imageIndex: number) => {
			return (event: KeyboardEvent) => {
				switch (event.key) {
					case 'ArrowLeft': {
						if (imageIndex > 0) {
							onNavigationChange(imageIndex - 1);
						}
						break;
					}

					case 'ArrowRight': {
						if (imageIndex < mediaLength - 1) {
							onNavigationChange(imageIndex + 1);
						}
						break;
					}
					default:
						break;
				}
			};
		},
		[mediaLength, onNavigationChange],
	);

	useEffect(() => {
		const keydownEvent = keydown(activeImageIndex);
		window.addEventListener('keydown', keydownEvent);
		return () => {
			window.removeEventListener('keydown', keydownEvent);
		};
	}, [activeImageIndex, keydown]);

	return (
		<CarouselWithStaticVisibleSlots
			visibleSlots={1}
			navigationIndex={activeImageIndex}
			onNavigationIndexChange={onNavigationChange}
			renderNextButton={renderNextButton}
			renderPrevButton={renderPreviousButton}
			{...useCarouselSize()}
		>
			{children}
		</CarouselWithStaticVisibleSlots>
	);
};

export default Carousel;
