import { buildAnalyticsEvent } from '@utils/buildAnalyticsEvent';
import Icon from '@xo-union/tk-component-icons';

import { useLiteStorefront } from '@hooks/use-lite-storefront';
import React, { VFC } from 'react';
import { Link } from 'react-scroll';
import { Track } from 'types/analytics';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';

interface PhoneProps {
	isFooter?: boolean;
	track: Track;
	vendor: Vendor.Decorated;
	goTo?: string;
}

const Phone: VFC<PhoneProps> = (props) => {
	const isLiteStorefront = useLiteStorefront();
	const { isFooter, track, vendor, goTo } = props;

	if (!vendor.phone || isFooter || isLiteStorefront) {
		return null;
	}

	const onClickHandler = () => {
		track(
			buildAnalyticsEvent({
				analyticsEventType: AnalyticsConstants.CALL_VENDOR_INITIATED,
				analyticsProperties: {
					destination: 'phone',
					userDecisionArea: 'Vendor Info',
				},
				vendor,
			}),
		);
	};
	const to = goTo || 'navContact';
	const href = `#${to}`;

	return (
		<Link
			href={href}
			to={to}
			smooth
			offset={-50}
			onClick={onClickHandler}
			title="Go to contact section"
		>
			<Icon name="phone" size="md" data-testid="phone-icon" />
		</Link>
	);
};

export default Phone;
