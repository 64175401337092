import { useOpenLightbox } from '@components/lightbox/hooks/use-open-lightbox';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import classNames from 'classnames';
import React, { FC } from 'react';
import AnalyticsConstants from '../../../../../../../../../constants/analytics';
import VendorAnalytics from '../../../../../../../../decorators/vendorAnalytics';
import Styles from './MediaCTA.scss';
import { ButtonRole, RoundUpOrientation } from './constants';

export interface MediaCTAProps {
	mediaItems: Vendor.IndexedMedia[];
	event: string;
	role: ButtonRole;
	round?: RoundUpOrientation;
	singleButton?: boolean;
	action?: string;
	sourceContent: string;
	onClick?: (index: number) => void;
}
export const MediaCTA: FC<MediaCTAProps> = (props: MediaCTAProps) => {
	const {
		onClick,
		mediaItems,
		singleButton,
		event,
		role,
		round,
		sourceContent,
		action,
	} = props;

	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);
	const openLightbox = useOpenLightbox();
	const { track } = useAnalyticsContext();

	const trackClick = () => {
		const vendorAnalytics = new VendorAnalytics(vendor);
		const sourcePage =
			vendor.purchaseStatus === 'PAID'
				? AnalyticsConstants.PAID_STOREFRONT
				: AnalyticsConstants.UNPAID_STOREFRONT;
		track({
			event,
			properties: {
				action: action ? action : AnalyticsConstants.CLICK_MOSAIC,
				sourcePage,
				sourceContent: sourceContent
					? sourceContent
					: AnalyticsConstants.MOSAIC_CTA,
				...vendorAnalytics.baseEventProps(),
			},
		});
	};

	const onClickThru = () => {
		openLightbox({ index: mediaItems[0].index });
		trackClick();
	};

	const handleOnClick = () => {
		if (onClick) {
			onClick(mediaItems[0].index);
			trackClick();
		} else {
			onClickThru();
		}
	};

	const buttonText = `${mediaItems.length} ${
		role === ButtonRole.Video ? 'Video' : 'Virtual Tour'
	}${mediaItems.length > 1 ? 's' : ''}`;

	const iconName = role === ButtonRole.Video ? 'play' : 'virtual_tour';

	const getRoundStyle = () => {
		if (singleButton) {
			return classNames(Styles.leftRoundCorner, Styles.rightRoundCorner);
		}

		if (round === RoundUpOrientation.Left) {
			return Styles.leftRoundCorner;
		}

		return Styles.rightRoundCorner;
	};

	return (
		<button
			className={classNames(Styles.button, getRoundStyle())}
			type="button"
			name={`MediaCTA-${role}`}
			aria-label={`MediaCTA-${role}`}
			onClick={handleOnClick}
		>
			<Icon
				className={classNames(
					Styles.galleryCtasText,
					isMobile && role === ButtonRole.Video ? Styles.hideOnMobile : '',
				)}
				name={iconName}
				size="md"
			/>
			{!isMobile && (
				<Body2
					color="white"
					bold
					className={classNames(
						Styles.galleryCtasText,
						!isMobile ? Styles.textSpacing : '',
					)}
				>
					{buttonText}
				</Body2>
			)}
		</button>
	);
};
