import NavigableModal from '@components/NavigableModal/NavigableModal';
import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import { ModalHeader } from '@xo-union/tk-component-modals';
import { Body1 } from '@xo-union/tk-ui-typography';
import delay from 'lodash/delay';
import React, { VFC, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import { Decorated } from 'types/vendor';
import { ShareLink } from './components/share_link';

import Styles from './styles.scss';

interface Props {
	modalIsOpen: boolean;
	onClose: () => void;
}

export const ShareModal: VFC<Props> = ({ modalIsOpen, onClose }) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const { storefrontCard, uriData } = vendor;

	const linkCopiedRef = useRef<HTMLDivElement>(null);

	const showNotification = () => {
		if (linkCopiedRef.current) {
			ReactTooltip.show(linkCopiedRef.current);
			delay(ReactTooltip.hide, 2000, linkCopiedRef.current);
		}
	};

	return (
		<NavigableModal
			size="md"
			closeCallback={() => onClose()}
			hash="desktop-share-modal"
			isModalOpen={modalIsOpen}
		>
			<ModalHeader className={Styles.modalHeader}>
				Share this vendor
			</ModalHeader>
			<div className={Styles.shareLinksContainer}>
				<div className={Styles.shareLinkBox}>
					<ShareLink url={uriData.uri} text="Email" icon="email" />
				</div>
				<div className={Styles.shareLinkBox}>
					<CopyToClipboard
						text={uriData.uri}
						onCopy={showNotification}
						data-testid="test-copy-to-clipboard"
					>
						<div className={Styles.shareLink}>
							<Icon name="url" size="md" className={Styles.shareLinkImage} />
							<Body1>Copy link</Body1>
						</div>
					</CopyToClipboard>
					<div ref={linkCopiedRef} data-tip="Link Copied!" />
					<ReactTooltip place="right" type="light" border delayShow={500} />
				</div>
				<div className={Styles.shareLinkBox}>
					<ShareLink url={uriData.uri} text="Facebook" icon="social_facebook" />
				</div>
				<div className={Styles.shareLinkBox}>
					<ShareLink url={uriData.uri} text="Twitter" icon="social_twitter" />
				</div>
				<div className={Styles.shareLinkBox}>
					<ShareLink
						url={uriData.uri}
						image={storefrontCard.url}
						text="Pinterest"
						icon="social_pinterest"
					/>
				</div>
			</div>
		</NavigableModal>
	);
};
