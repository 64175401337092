import React from 'react';
import PlaceHolderStyles from '../../../../components/Media/styles.scss';
import Styles from '../../styles.scss';

const GalleryPlaceHolder = () => (
	<div className={Styles.placeholderContainer}>
		<div className={PlaceHolderStyles.placeholderImage} role="presentation" />
	</div>
);

export default GalleryPlaceHolder;
