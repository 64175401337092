import {
	PHOTO,
	TOUR360,
	VIDEO,
} from '../../../../../../../../../constants/media';

export const bucketMedia = (media: Vendor.Media[]) => {
	return media.reduce(
		(acc, mediaItem) => {
			acc.get(mediaItem.mediaType)?.push(mediaItem);

			return acc;
		},
		new Map<Vendor.MediaType, Vendor.Media[]>([
			[PHOTO, []],
			[VIDEO, []],
			[TOUR360, []],
		]),
	);
};

export const getMediaQuickTileText = (mediaBucket: Vendor.Media[]) => {
	const typeToTextMap = {
		[PHOTO]: 'Photo',
		[VIDEO]: 'Video',
		[TOUR360]: 'Virtual Tour',
	};

	const mediaBucketLength = mediaBucket.length;
	const shouldPluralize = mediaBucketLength > 1;

	return `${mediaBucketLength} ${typeToTextMap[mediaBucket[0].mediaType]}${
		shouldPluralize ? 's' : ''
	}`;
};
