import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { connect } from 'react-redux';
import { useInlineRfqForm } from '../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import useLightboxModal from '../../hooks/useLightboxModal';
import { ModalWrapper } from '../common/modal_wrapper/ModalWrapper';
import LightboxModal from '../lightbox_modal/LightboxModal';
import useTrackOverviewInteraction, {
	DetermineActionType,
} from './hooks/use-track-overview-interaction';
import MediaView from './media-view';
import PlayableMediaView from './playable-media-view';
import Styles from './styles.scss';

const preloadRange = 6;

interface StateProps {
	vendor: Vendor.Decorated;
}

interface OwnProps {
	isModalOpen: boolean;
	toggleModal: () => void;
	setActiveImageIndex?: Dispatch<SetStateAction<number>>;
	activeImageIndex?: number;
	carouselMedia?: Vendor.Media[];
	isQuickLinkClick?: boolean;
	mediaType?: Vendor.Media['mediaType'];
	context?: ReturnType<typeof useInlineRfqForm>;
}

type OverviewModalProps = StateProps & OwnProps;

export const OverviewModal = (props: OverviewModalProps) => {
	const {
		activeImageIndex = 0,
		carouselMedia = [],
		setActiveImageIndex,
		isQuickLinkClick,
		mediaType,
		isModalOpen,
		toggleModal,
		vendor,
		context,
	} = props;

	const [overviewIndex, setOverviewIndex] = useState(activeImageIndex);
	const [isLightboxModalOpen, toggleLightboxModal] = useLightboxModal();
	const mediaRefs = useRef<Array<HTMLDivElement | null>>([]);
	const currentRange = useMemo(
		() => ({
			maxRange: overviewIndex + preloadRange,
			minRange: overviewIndex - preloadRange,
		}),
		[overviewIndex],
	);

	const trackOverviewInteraction = useTrackOverviewInteraction(vendor);

	const openLightbox = useCallback(
		(index: number, mediaTypeClicked: Vendor.MediaType) => {
			if (setActiveImageIndex) {
				setActiveImageIndex(index);
			}
			trackOverviewInteraction(DetermineActionType(mediaTypeClicked), index);
			setOverviewIndex(index);
			toggleLightboxModal();
		},
		[
			trackOverviewInteraction,
			setOverviewIndex,
			toggleLightboxModal,
			setActiveImageIndex,
		],
	);

	const closeOverview = useCallback(() => {
		trackOverviewInteraction('dismiss');
		toggleModal();
	}, [toggleModal, trackOverviewInteraction]);

	useEffect(() => {
		mediaRefs.current[activeImageIndex]?.scrollIntoView();
	}, []);

	return (
		<>
			<ModalWrapper
				hash="overview-modal"
				isModalOpen={isModalOpen}
				closeModal={closeOverview}
				mediaLength={carouselMedia.length}
				vendorName={vendor.name}
				isQuickLinkClick={isQuickLinkClick}
				mediaType={mediaType}
				sourceContent="media overview"
				context={context}
			>
				<div className={Styles.content}>
					{carouselMedia.map((media, i) => {
						const MediaViewComponent =
							media.mediaType === '360TOUR' ? PlayableMediaView : MediaView;
						return (
							<MediaViewComponent
								currentRange={currentRange}
								key={media.id}
								index={i}
								ref={(el) => {
									mediaRefs.current[i] = el;
								}}
								setOverviewIndex={setOverviewIndex}
								mediaItem={media}
								onClick={(index) => openLightbox(index, media.mediaType)}
							/>
						);
					})}
				</div>
			</ModalWrapper>
			{isLightboxModalOpen && (
				<LightboxModal
					carouselMedia={carouselMedia}
					mediaType={mediaType}
					overviewIndex={overviewIndex}
					setActiveImageIndex={setActiveImageIndex}
					isModalOpen={isLightboxModalOpen}
					toggleModal={toggleLightboxModal}
					closeOverviewModal={toggleModal}
					context={context}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: Redux.State): StateProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(
	OverviewModal,
);
