import React, { FC } from 'react';
import { connect } from 'react-redux';
import { useInlineRfqForm } from '../../../../InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import useTrackQuickLinksInteraction, {
	getQuickLinkSourceContent,
} from './hooks/use-track-quick-links-interaction';
import { MobileGalleryQuickLinkItem } from './quick_link_item';

interface StateProps {
	vendor: Vendor.Decorated;
}

interface OwnProps {
	mediaBuckets: [Vendor.MediaType, Vendor.Media[]][];
	context?: ReturnType<typeof useInlineRfqForm>;
}

type MobileGalleryQuickLinkCollectionProps = StateProps & OwnProps;

const MobileGalleryQuickLinkCollection: FC<
	MobileGalleryQuickLinkCollectionProps
> = (props) => {
	const { mediaBuckets, vendor } = props;

	const trackQuickLinkInteraction = useTrackQuickLinksInteraction(vendor);

	const trackQuicklinksClick = (type: Vendor.MediaType) => {
		trackQuickLinkInteraction(getQuickLinkSourceContent(type));
	};

	return (
		<>
			{mediaBuckets.map(([, mediaBucket]) => {
				const mediaItem = mediaBucket && mediaBucket[0];

				if (!mediaItem) {
					return null;
				}

				return (
					<MobileGalleryQuickLinkItem
						key={`quick-link-${mediaItem.mediaType}S`}
						trackQuicklinksClick={trackQuicklinksClick}
						mediaBucket={mediaBucket}
						context={props.context}
					/>
				);
			})}
		</>
	);
};

const mapStateToProps = (state: Redux.State): StateProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
});

export default connect<StateProps, {}, OwnProps>(mapStateToProps)(
	MobileGalleryQuickLinkCollection,
);
