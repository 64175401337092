import { useState } from 'react';

type UseLightboxModalReturnValues = [boolean, () => void];

const useLightboxModal = (): UseLightboxModalReturnValues => {
	const [isLightboxModalOpen, setIsLightboxModalOpen] = useState(false);

	const toggleLightboxModal = () => {
		setIsLightboxModalOpen(!isLightboxModalOpen);
	};

	return [isLightboxModalOpen, toggleLightboxModal];
};

export default useLightboxModal;
