import { useState } from 'react';

type UseOverviewModalReturnValues = [boolean, () => void];

const useOverviewModal = (): UseOverviewModalReturnValues => {
	const [isOverviewModalOpen, setIsOverviewModalOpen] = useState(false);

	const toggleOverviewModal = () => {
		setIsOverviewModalOpen(!isOverviewModalOpen);
	};

	return [isOverviewModalOpen, toggleOverviewModal];
};

export default useOverviewModal;
