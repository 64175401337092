import { ClientOnly } from '@components/shared/ClientOnly';
import cx from 'classnames';
import React, { FC } from 'react';
import { Widget } from '../../../../containers/widget';

import Styles from './styles.scss';
import AlgorithmType = Widget.AlgorithmType;

interface LiteWidgetProps {
	algorithmType: AlgorithmType;
	className?: string;
}

export const LiteWidget: FC<LiteWidgetProps> = ({
	algorithmType,
	className,
}) => (
	<ClientOnly>
		<div className={cx(className, Styles.wrapper)}>
			<Widget
				className={Styles.widget}
				cardType="sm"
				algorithmType={algorithmType}
				impressionType="Rec Module: Storefront - Bottom"
				widgetName="lite-storefront"
			/>
		</div>
	</ClientOnly>
);
